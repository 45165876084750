import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { AuthProvider } from './context/AuthProvider'
// import PrivateRoute from './utils/PrivateRoute'
import RequireAuth from './features/auth/RequireAuth'
import Unauthorized from './views/pages/unauthorized/Unauthorized'

import UsersList from './features/users/UsersList'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            {/* <Route exact path="/protected" element={<PrivateRoute />}>
                <Route exact path="/protected" element={<Login />} />
              </Route> */}

            {/* <Route element={<RequireAuth />}>
              <Route path="/ula" element={<UsersList />} />
               <Route path="/project/*" element={<DefaultLayout />} />
            </Route> */}

            <Route element={<RequireAuth allowedRoles={['superadmin']} />}>
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={['superadmin', 'admin']} />}>
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
            </Route>

            {/* <Route exact path="/login" name="Login Page" element={<Login />} /> */}
            {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
            <Route exact path="/unauthorized" name="Unauthorized Page" element={<Unauthorized />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
