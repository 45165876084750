import { configureStore } from '@reduxjs/toolkit'
import themeReducer from '../features/theme/themeSlice'

import { apiSlice } from './api/apiSlice'
import authReducer from '../features/auth/authSlice'

// parametr -> reducer
export const store = configureStore({
  reducer: {
    theme: themeReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
})
