import { createSlice } from '@reduxjs/toolkit'
import decode from 'jwt-decode'

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null, is_superuser: false, refresh_token: null },
  reducers: {
    setCredentials: (state, action) => {
      const { user, access, refresh } = action.payload

      const decoded = decode(access)
      state.user = user
      state.is_superuser = decoded.is_superuser
      state.token = access
      state.refresh_token = refresh
    },
    logOut: (state, action) => {
      state.user = null
      state.is_superuser = false
      state.token = null
      state.refresh_token = null
    },
  },
})

export const { setCredentials, logOut, getRefreshToken } = authSlice.actions
export default authSlice.reducer
export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentUserIsSuperuser = (state) => state.auth.is_superuser
export const selectCurrentToken = (state) => state.auth.token
