import React from 'react'
import { CButton, CCol, CContainer, CRow } from '@coreui/react-pro'
import { useNavigate } from 'react-router-dom'
const Unauthorized = () => {
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <div className="clearfix">
              <h4 className="pt-3">Unauthorized access</h4>
            </div>
            <CButton onClick={goBack}>Go back</CButton>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Unauthorized
