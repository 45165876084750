//const BASE_API_URL = 'http://127.0.0.1:80'
const BASE_API_URL = 'https://rdis.cs.put.poznan.pl'
const BASE_APP_URL = 'https://rdis.cs.put.poznan.pl'

const loginURL = BASE_API_URL + '/api/v1/login/'

const MAIN_DISORDER_SEARCH_URL = '/api/v1/search/'
const DISORDER_TOP_CLASSIFICATIONS_URL = '/api/v1/disordersTopClassification/'
const DISORDER_INHERITANCE_TYPES_URL = '/api/v1/disordersInheritanceTypes/'

const GENES_WITH_DISORDERS_URL = '/api/v1/genesDisorders'
const GENES_COUNT_URL = '/api/v1/count/genes'
const DISORDER_GENES_URL = '/api/v1/genesForDisorder/'
const DISORDER_NAME_URL = '/api/v1/disorderName/'
const DISORDER_FULL_URL = '/api/v1/disordersXFull/'
const TRANSCRIPTS_FOR_GENES_SYMBOL_URL = '/api/v1/ens_transcript/gene/'
const TRANSCRIPTS_FOR_GENE_ID_URL = '/api/v1/ens_transcript/gene/id/'

const TRANSCRIPTS_URL = '/api/v1/transcripts'
const TRANSCRIPTS_COUNT_URL = '/api/v1/count/transcripts/'

const UTR5S_URL = '/api/v1/utrs/5'
const UTR5S_COUNT_URL = '/api/v1/count/utrs5/'
const UTR3S_URL = '/api/v1/utrs/3'
const UTR3S_COUNT_URL = '/api/v1/count/utrs3/'

const AAVS_LIST_URL = '/api/v1/aavs'
const AAVS_LIST_COUNT_URL = '/api/v1/count/aavs'

const EPD_PROMOTERS_LIST_ALL_URL = '/api/v1/epd_promoters/list/all'
const EPD_PROMOTERS_LIST_CODING_URL = '/api/v1/epd_promoters/list/coding/'
const EPD_PROMOTERS_LIST_NONCODING_URL = '/api/v1/epd_promoters/list/noncoding/'
const EPD_PROMOTERS_COUNT_ALL_URL = '/api/v1/count/epd_promoters/all'
const EPD_ADV_LOCATION_NAMES_LIST = 'api/v1/epd_promoters/hpa_loc_names/'
const EPD_ADV_SEARCH_URL = 'api/v1/epd_promoters/hpa_search/'

const DICT_PROMOTERS_COUNT_URL = '/api/v1/count/dict_promoters/'

const VH_VIRUSES_SEARCH_URL = '/api/v1/virus/search'
const VH_VIRUSES_BASIC_URL = '/api/v1/virus/basic/'
const VH_VIRUSES_DETAILS_URL = '/api/v1/virus/details/'
const VH_VIRUSES_COUNT_URL = '/api/v1/count/viruses/'
const VH_VIRAL_SEQUENCES_LIST_URL = '/api/v1/viral/list/'
const VH_VIRAL_SEQUENCES_COUNT_URL = '/api/v1/count/viral/'

const PROJECT_CREATE_URL = '/api/v1/project_json/create'
const PROJECT_SEARCH_URL = '/api/v1/project_json/search/'
const PROJECT_DETAILS_BY_ID_URL = '/api/v1/project_json/uuid/'
const PROJECT_DEBUG_BY_ID_URL = '/api/v1/project_json/manage/debug/'
const PROJECT_INPUT_JSON_GET = 'api/v1/project_json/manage/input/'
const PROJECT_SET_DESCRIPTION_URL = '/api/v1/project_json/description/edit/'
const PROJECT_ADD_GENE_URL = '/api/v1/project_json/gene/add/'
const PROJECT_ADD_CUSTOM_GENE_URL = '/api/v1/project_json/gene/add/custom/'
const PROJECT_MOVE_GENE_URL = '/api/v1/project_json/gene/move/'
const PROJECT_REMOVE_GENE_URL = '/api/v1/project_json/gene/remove/'
const PROJECT_ADD_VIRAL_GENE_URL = '/api/v1/project_json/viral_gene/add/'
const PROJECT_MOVE_VIRAL_GENE_URL = '/api/v1/project_json/viral_gene/move/'
const PROJECT_REMOVE_VIRAL_GENE_URL = '/api/v1/project_json/viral_gene/remove/'
const PROJECT_SET_USE_FLUORESCENT_TAG =
  '/api/v1/project_json/settings/fluorescent_tag_required/set/'
const PROJECT_SET_USE_ADDITIONAL_EXPRESSION = '/api/v1/project_json/addit_expression_required/set/'
const PROJECT_SET_USE_TRANSCRIPTS_OPTIMIZATION_URL =
  '/api/v1/project_json/settings/transcripts_optimize/set/'
const PROJECT_SET_TRANSCRIPT_FOR_GENE_URL = '/api/v1/project_json/gene/transcript/set/'
const PROJECT_SET_TRANSCRIPT_SEQUENCE_FOR_CUSTOM_GENE_URL =
  '/api/v1/project_json/gene/transcript/custom/set/'
const PROJECT_GET_TRANSCRIPT_SEQUENCE_FOR_CUSTOM_GENE_URL =
  '/api/v1/project_json/gene/transcript/custom/get/'
const PROJECT_SET_LINKER_URL = '/api/v1/project_json/linker/set/'
const PROJECT_SET_VIRUS_LINKER_URL = '/api/v1/project_json/linker_virus/set/'
const PROJECT_SET_POLYATAIL_URL = '/api/v1/project_json/polyatail/set/'
const PROJECT_SET_ELEMENT_URL = '/api/v1/project_json/element/set/'
const PROJECT_GET_CUSTOM_ELEMENT_SEQUENCE_URL = '/api/v1/project_json/element/custom/sequence/'
const PROJECT_SET_USE_SEPARATE_PROMOTER_FLUORESCENT_TAG =
  '/api/v1/project_json/fluorescent_tag/separate_prom/set/'
const PROJECT_SET_FLUORESCENT_ELEMENT = '/api/v1/project_json/fluorescent/element/set/'
const PROJECT_ADD_REMOVE_RESTRICTION_PLACE = 'api/v1/project_json/restriction_place/change/'
const PROJECT_SET_GENE_TO_SILENCE_URL = 'api/v1/project_json/gene_silence/set/'
const PROJECT_GET_CUSTOM_GENE_TO_SILENCE_SEQ_URL =
  'api/v1/project_json/gene_silence/custom/sequence/'
const PROJECT_SET_GENE_TYP_FLOW4_URL = 'api/v1/project_json/settings/gene_type/set/'

const PROJECT_LIST_URL = '/api/v1/manage/project/list'
const PROJECT_LITE_LIST_URL = '/api/v1/manage/project/list/lite'

const DICTIONARY_URL = '/api/v1/dictionaryCSV/'
const DICT_PROMOTERS_LIST_URL = '/api/v1/dictionary/promoters/list'
const DICT_PROMOTERS_CODING_LIST_URL = '/api/v1/dictionary/promoters/coding/list'
const DICT_PROMOTERS_NONCODING_LIST_URL = '/api/v1/dictionary/promoters/noncoding/list'
const DICT_LINKERS_LIST_URL = '/api/v1/dictionary/linkers/list'
const DICT_FLUORESCENT_TAGS_LIST_URL = '/api/v1/dictionary/fluorescent/list'
const DICT_REGULATORS_LIST_URL = '/api/v1/dictionary/regulators/list'
const DICT_POLYA_LIST_URL = '/api/v1/dictionary/polya/list'
const DICT_SELECTION_MARKERS_LIST_URL = '/api/v1/dictionary/selectors/list'
const DICT_RESTRICTIONS_LIST_URL = '/api/v1/dictionary/restrictions/list'
const DICT_LOOPS_LIST_URL = '/api/v1/dictionary/loops/list'

const PROJECT_RUN_PROJECT_SIMPLE_URL = '/api/v1/project/run/simple/'
const PROJECT_ADD_TO_QUEUE_URL = '/api/v1/queue/add/'
const PROJECT_GET_RESULTS_URL = '/api/v1/project_json/results/'

module.exports = {
  loginURL,
  BASE_API_URL,
  MAIN_DISORDER_SEARCH_URL,
  DISORDER_INHERITANCE_TYPES_URL,
  GENES_WITH_DISORDERS_URL,
  GENES_COUNT_URL,
  DISORDER_TOP_CLASSIFICATIONS_URL,
  DISORDER_GENES_URL,
  DISORDER_NAME_URL,
  TRANSCRIPTS_FOR_GENES_SYMBOL_URL,
  TRANSCRIPTS_FOR_GENE_ID_URL,
  TRANSCRIPTS_URL,
  TRANSCRIPTS_COUNT_URL,
  AAVS_LIST_URL,
  AAVS_LIST_COUNT_URL,
  EPD_PROMOTERS_LIST_ALL_URL,
  EPD_PROMOTERS_LIST_CODING_URL,
  EPD_PROMOTERS_LIST_NONCODING_URL,
  EPD_PROMOTERS_COUNT_ALL_URL,
  DICT_PROMOTERS_COUNT_URL,
  DICTIONARY_URL,
  PROJECT_CREATE_URL,
  PROJECT_SEARCH_URL,
  PROJECT_LIST_URL,
  PROJECT_LITE_LIST_URL,
  PROJECT_DETAILS_BY_ID_URL,
  PROJECT_INPUT_JSON_GET,
  PROJECT_ADD_GENE_URL,
  PROJECT_ADD_CUSTOM_GENE_URL,
  PROJECT_MOVE_GENE_URL,
  PROJECT_REMOVE_GENE_URL,
  PROJECT_SET_USE_TRANSCRIPTS_OPTIMIZATION_URL,
  PROJECT_SET_TRANSCRIPT_FOR_GENE_URL,
  PROJECT_SET_TRANSCRIPT_SEQUENCE_FOR_CUSTOM_GENE_URL,
  PROJECT_GET_TRANSCRIPT_SEQUENCE_FOR_CUSTOM_GENE_URL,
  PROJECT_GET_CUSTOM_ELEMENT_SEQUENCE_URL,
  PROJECT_SET_LINKER_URL,
  PROJECT_SET_ELEMENT_URL,
  PROJECT_SET_USE_FLUORESCENT_TAG,
  PROJECT_SET_USE_ADDITIONAL_EXPRESSION,
  PROJECT_SET_USE_SEPARATE_PROMOTER_FLUORESCENT_TAG,
  PROJECT_SET_FLUORESCENT_ELEMENT,
  PROJECT_ADD_REMOVE_RESTRICTION_PLACE,
  PROJECT_SET_GENE_TO_SILENCE_URL,
  PROJECT_GET_CUSTOM_GENE_TO_SILENCE_SEQ_URL,
  PROJECT_RUN_PROJECT_SIMPLE_URL,
  PROJECT_SET_DESCRIPTION_URL,
  DICT_PROMOTERS_LIST_URL,
  DICT_PROMOTERS_CODING_LIST_URL,
  DICT_PROMOTERS_NONCODING_LIST_URL,
  DICT_LINKERS_LIST_URL,
  DICT_FLUORESCENT_TAGS_LIST_URL,
  DICT_REGULATORS_LIST_URL,
  DICT_RESTRICTIONS_LIST_URL,
  DICT_POLYA_LIST_URL,
  DICT_SELECTION_MARKERS_LIST_URL,
  DICT_LOOPS_LIST_URL,
  DISORDER_FULL_URL,
  VH_VIRUSES_SEARCH_URL,
  VH_VIRUSES_BASIC_URL,
  VH_VIRUSES_DETAILS_URL,
  VH_VIRUSES_COUNT_URL,
  VH_VIRAL_SEQUENCES_LIST_URL,
  VH_VIRAL_SEQUENCES_COUNT_URL,
  UTR5S_URL,
  UTR5S_COUNT_URL,
  UTR3S_URL,
  UTR3S_COUNT_URL,
  PROJECT_SET_POLYATAIL_URL,
  PROJECT_SET_GENE_TYP_FLOW4_URL,
  PROJECT_ADD_VIRAL_GENE_URL,
  PROJECT_MOVE_VIRAL_GENE_URL,
  PROJECT_REMOVE_VIRAL_GENE_URL,
  PROJECT_SET_VIRUS_LINKER_URL,
  PROJECT_ADD_TO_QUEUE_URL,
  PROJECT_DEBUG_BY_ID_URL,
  PROJECT_GET_RESULTS_URL,
  EPD_ADV_LOCATION_NAMES_LIST,
  EPD_ADV_SEARCH_URL,
  BASE_APP_URL,
}
