import { apiSlice } from 'src/app/api/apiSlice'

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => '/api/v1/topHierarchiesClassifications',
      keepUnusedDataFor: 5,
    }),
  }),
})

//  ponizsza nazwa utworzona automatycznie
export const { useGetUsersQuery } = usersApiSlice
