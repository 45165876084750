import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, logOut } from 'src/features/auth/authSlice'
import { axiosPrivate } from 'src/api/axios'
import { BASE_API_URL } from 'src/api_urls'
import { Mutex } from 'async-mutex'

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_API_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

// create a new mutex
const mutex = new Mutex()

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)
  if (result?.error?.status === 401) {
    const refresh_token = api.getState().auth.refresh_token
    // sending refresh token to get new access token
    // change to axios due to POST requirements from simplejwt django
    //const refreshResult = await baseQueryPOST('/api/v1/token/refresh', api, extraOptions)
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        // if (!refresh_token) {
        //   //console.log('nie ma tokena refresh!')
        //   // return result
        // }
        //console.log('wysylam refresh tokena')
        // const refreshResult = await axios.post('/api/v1/token/refresh/', {
        const refreshResult = await axiosPrivate.post('/api/v1/token/refresh/', {
          refresh: refresh_token,
        })
        // console.log('nowy: ' + refreshResult.data.refresh)
        if (refreshResult?.data) {
          const user = api.getState().auth.user
          //store new acces token
          api.dispatch(setCredentials({ ...refreshResult.data, user }))
          // retry original query with new access token
          result = await baseQuery(args, api, extraOptions)
        } else {
          // other error - better logout
          api.dispatch(logOut())
        }
      } catch (err) {
        console.error(err)
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
})
