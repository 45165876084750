import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default', // default theme
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setThemeParams: (state, value) => {
      return { ...state, ...value.payload }
    },
  },
})

export default themeSlice.reducer
export const { setThemeParams } = themeSlice.actions
