import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentToken, selectCurrentUser } from './authSlice'
import React, { Component } from 'react'

const RequireAuth = () => {
  const token = useSelector(selectCurrentToken)

  // niepotrzebne
  // const u = useSelector(selectCurrentUser)
  console.log('RequireAuth1')
  // console.log(u)

  const location = useLocation()
  console.log(token)
  console.log('RequireAuth2')
  console.log(location)

  return token ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />
}

export default RequireAuth
