import { createContext, useState, useEffect } from 'react'
import React from 'react'
import axiosPrivate from 'src/api/axios'
import { useDispatch } from 'react-redux'
import { setCredentials } from 'src/features/auth/authSlice'
const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState()
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const dispatch = useDispatch()

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  // podczas ładowania strony sprawdźmy, czy nie jesteśmy zalogowani
  // W tym celu wysyłamy (przeglądarka sama wysyła httponly cookie z tokenem refresh). /
  // Jeżeli taki token mamy i jest ważny to backend zwróci dane zalogowanego użytkownika
  // Jeżeli nie, zostanie zwrócony komunikat error.
  useEffect(() => {
    //console.log('sprawdzam czy zalogowany')
    setIsAuthenticating(true)
    async function fetchData() {
      const response = await axiosPrivate.post('/api/v1/auth/me/', JSON.stringify({}), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      setAuth((prev) => {
        if (response.data.status === 'ok') {
          const user = response.data.username
          //console.log('ZALOGOWANY')
          dispatch(setCredentials({ ...response.data, user }))
          setIsAuthenticating(false)
        } else {
          //console.log('NIEZALOGOWANY')
          setIsAuthenticating(false)
        }
        return { ...prev, accessToken: response.data.accessToken }
      })
    }
    fetchData()
    setIsAuthenticating(false)
  }, [])

  if (isAuthenticating) {
    //await sleep(i * 1000);
    //console.log('isAuthenticating')
    return null // or loading
  }
  return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>
}

export default AuthContext
